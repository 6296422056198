.text-gray {
  color: var(--description-color-1) !important;
}

.text-green {
  color: #04e9a6 !important;
}

.text-disabled {
  color: #a8a8aa;
}

.price-text-size-12 {
  @include price_text_size_12;
}

.price-text-size-14 {
  @include price_text_size_14;
}

.price-text-size-16 {
  @include price_text_size_16;
}

.main-information-title-14 {
  @include main_information_title_14;
}

.main-information-title-15 {
  @include main_information_title_15;
}

.main-information-title-14-medium {
  @include main_information_title_14_medium;
}

.main-information-description-12 {
  @include main_information_description_12;
}

.main-information-description-10 {
  @include main_information_description_10;
}

.main-information-description-8 {
  @include main_information_description_8;
}
