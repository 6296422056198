:root {
  --text-color: #fff;
  --background-color: #1a1926;
  --main-color-1: #232334;
  --message-girl: #262639;
  --message-user: #2f364d;
  --description-color-1: #777797;
  --card-color-1: #2e2e44;
  --main-color-active: #ff00a8;
  --main-color-active-opacity2: #582558;
  --icons-tabbar-active: #ff59b5;
  --icons-tab-bar-inactive: #7f7fa2;
  --notification-color: #f945a2;
  --link-color: #3994ff;
  --blue-text-color: #007aff;
  --button-color: #232334;
  --button-hover-background: #111121;
  --button-main-shadow-shine: 0px -0.25rem 0.125rem 0px rgba(0, 0, 0, 0.5) inset,
    0px 0px 0.25rem 0.125rem rgba(251, 70, 167, 0.25);
  --main-card-effects: 0px 0px 0.375rem 0px rgba(0, 0, 0, 0.5),
    0px 0.07rem 0.25rem 0px rgba(0, 0, 0, 0.25) inset;
  --gradient-main: linear-gradient(180deg, #fc46a4 0%, #da2ff5 100%);
  --card-shadow-small: 0px 0.125rem 0.625rem rgba(0, 0, 0, 0.15);
}
