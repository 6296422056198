//* TEXT

@mixin price_text_size_16 {
  font-weight: 700;
  font-size: 1rem;
  line-height: 120%;
}

@mixin price_text_size_14 {
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 120%;
}

@mixin price_text_size_12 {
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 120%;
}

@mixin main_information_title_14 {
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 100%;
}

@mixin main_information_title_15 {
  font-weight: 700;
  font-size: 0.938rem;
  line-height: 0.875rem;
}

@mixin main_information_title_14_medium {
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 120%;
}

@mixin main_information_description_12 {
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 120%;
}

@mixin main_information_description_10 {
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 120%;
}

@mixin main_information_description_8 {
  font-weight: 500;
  font-size: 0.5rem;
  line-height: 120%;
}
