@import "variables";
@import "mixins";
@import "typography";
@import "./components/button";
@import "./components/modal";

ion-content {
  --padding-start: 1.5625rem;
  --padding-end: 1.5625rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: 120%;
}

.spacer {
  flex-grow: 1;
}
