@import "./assets/scss/_theme";
@import "./assets/scss/_ionic";
@import "./assets/scss/new/adaptive-size.scss";
@import "./assets/scss/new";
@import "ngx-toastr/toastr";
@import "src/assets/fonts/Inter/stylesheet.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;

  --ion-default-font: "Inter", sans-serif !important;
}
body {
  font-family: "Inter", sans-serif !important;
  margin: 0;
  min-height: 100%;
  height: 100%;
  overflow: hidden;
  --webkit-user-select: none;
  color: var(--text-color);
}

p {
  user-select: none;
}

ul,
ol {
  list-style-type: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  resize: none;
}

button {
  border: none;
  outline: none;
  background: transparent;
  color: var(--text-color);
}
img {
  object-fit: contain;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.w-100 {
  width: 100%;
}

.content-box {
  height: calc(100% - 147px);
  flex: 1 1 auto;
  padding: 20px 10px 5px;

  // overflow: auto;
  @media (max-width: 400px) {
    padding: 20px 10px 5px;
  }
}

.btn {
  border-radius: 6.25rem;
  font-size: 1rem;
  line-height: 1.171875;
  font-weight: 600;
  vertical-align: middle;
  text-transform: uppercase;
  height: 3.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.btn-block {
  width: 100%;
  display: flex;
}

.btn-uppercase {
  text-transform: uppercase;
}

.btn-grey {
  color: #777d87;
  background: rgba(120, 117, 136, 0.2);
}

.btn-green {
  color: #fff;
  background: rgba(34, 193, 79, 1);
}

.btn-blue {
  background: linear-gradient(180deg, #3b5998 0%, #355392 100%);
  box-shadow: 0px 2px 10px rgba(59, 89, 152, 0.55);
  color: #fff;
}

.btn-primary {
  // background: linear-gradient(155.33deg, #ffa183 8.78%, #ff3975 88.79%);
  // box-shadow: 0px 4px 10px rgba(255, 65, 117, 0.5);
  color: #fff;
}

.form-control {
  width: 100%;
  border: none;
  background: rgba(153, 135, 254, 0.1);
  border-radius: 100px;
  color: #333d49;
  height: 50px;
  padding: 0 30px 0 20px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 15px;

  &:focus {
    outline: none;
  }

  &.error {
    border: 3px solid rgba(252, 53, 53, 0.15);
  }
}

textarea.form-control {
  padding: 10px;
  height: 100px;
  border-radius: 25px;
}

.form-group {
  margin-bottom: 10px;
}

.form-offset {
  margin-bottom: 20px;
}
.form-offset-double {
  margin-bottom: 40px;
}

.form-group-hidden {
  display: none;
}

.form-tab {
  display: flex;
  width: 213px;
  margin: 0 auto 34px;
  justify-content: space-between;

  .tab {
    color: #4e5682;
    background: none;
    border: none;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;
    opacity: 0.4;
    position: relative;

    &.active {
      // opacity: 1;
      // color: #ff826a;

      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -6px;
        left: 50%;
        transform: translateX(-50%);
        background-color: currentColor;
        width: 11px;
        height: 3px;
        border-radius: 2px;
      }
    }
  }
}

.modal-container {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  bottom: 0;

  &.animate-bottom {
    animation: animatebottom 0.4s;
  }

  @keyframes animatebottom {
    from {
      bottom: -600px;
      opacity: 0;
    }

    to {
      bottom: 0;
      opacity: 1;
    }
  }
}

.modal {
  background: #ffffff;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  padding: 5px 5px 30px 5px;
  position: absolute; //fixed
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  max-height: 100%;
  overflow: auto;
  z-index: 556;

  @media (max-width: 320px) {
    width: 300px !important;
  }

  &.modal-no-padding {
    border: 0;
    padding: 0;
    background: transparent;
  }
  &.modal-vertical {
    height: 600px;
    width: 268px;
    max-height: 100%;
    overflow: visible;

    &.modal-auto-height {
      height: auto;
    }
  }

  .modal-head {
    font-size: 21px;
    line-height: 25px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: #323b46;
    background: #f5f3ff;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 15px;
    text-align: center;
    margin-bottom: 20px;
    position: relative;

    &--warning {
      color: #ff5555;
      background: rgba(255, 85, 85, 0.1);
    }

    &--info {
      color: #004085;
      background-color: #cce5ff;
    }

    &--light {
      color: #888888;
      background-color: #fff;
      font-size: 16px;
      font-weight: bold;
      font-family: Tahoma, Arial, Helvetica, sans-serif;
      margin-bottom: 0;
    }
  }

  .timer-box {
    margin-bottom: 12px;
  }

  .item-img {
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 68px;
    height: 68px;
    border-radius: 50%;
    overflow: hidden;
    background: linear-gradient(155.33deg, #ffc632 8.77%, #e54952 88.79%);
    padding: 4px;
    margin: 0 auto 15px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .timer {
    text-align: center;
    font-size: 26px;
    line-height: 32px;
    color: #ed403a;
    display: flex;
    align-items: center;
    justify-content: center;
    &.timer-info {
      color: #004085;
    }
  }

  .circle {
    width: 120px;
    height: 120px;
    margin: 0 auto 15px;
    background-color: rgba($color: #9987fe, $alpha: 0.1);
    border-radius: 50%;
    padding: 16px;
    position: relative;
    & > div {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: rgba($color: #f23232, $alpha: 0.12);
      padding: 9px;

      & > div {
        width: 100%;
        height: 100%;
        border-radius: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(180deg, #d7375e 0%, #f25d54 100%);
      }
    }
  }

  .info-text {
    font-weight: bold;
    text-align: center;
  }

  .info-img {
    display: block;
    margin: 0 auto;
  }

  .modal-body {
    // width: 270px;
    padding: 0 20px;
    margin: 0 auto;
  }

  .modal-content {
    background-color: var(--card-color-1);
    padding: 0 20px;
    max-height: 400px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 6px;
      height: 48px;
      border-radius: 15px;
      background-color: #f5f3ff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ff826a;
      border-radius: 15px;
    }
  }

  .prev,
  .close {
    width: 40px;
    height: 40px;
    font-size: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: #ffffff;
    border-radius: 10px;
    position: absolute;
    color: #ff826a;
  }

  .prev {
    left: 10px;
  }

  .close {
    right: 10px;
  }

  .text {
    color: #333d49;
    font-size: 16px;
    line-height: 1.4375;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
  }

  .btn-group {
    display: flex;
    justify-content: space-between;

    .btn {
      width: 130px;
    }
  }

  &--congratulations {
    background-image: url(assets/img/pattern-bg.png),
      linear-gradient(155.33deg, #ffc632 8.77%, #e54952 88.79%);
    box-shadow: 0px 6px 15px rgba(252, 196, 91, 0.3);
    border: none;

    .text {
      color: #fff;

      .reward-current {
        font-size: 26px;
        line-height: 31px;
        font-size: 600;
        color: #ffdf74;
        text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.19);
      }
    }

    .btn-primary {
      box-shadow: none;
      border: 4px solid #fff;
      text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
      height: 62px;
    }

    .modal-head {
      background: rgba(47, 29, 99, 0.2);
      border: none;
      color: #fff;
    }

    .circle {
      background: rgba(255, 255, 255, 0.12);

      & > div {
        background: rgba(255, 255, 255, 0.35);

        & > div {
          background: transparent;
        }
      }
    }
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  position: relative;
  color: rgba(0, 0, 0, 0) !important;
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
  z-index: 1;
}

.spinner-inline {
  width: 10px;
  display: inline-block;
  position: relative;
}

.spinner-inline:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 100%;
  width: 10px;
  height: 10px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
  z-index: 1;
}

.transparent-modal {
  .swiper-pagination-bullets {
    height: 20px;
    top: 0;
    .swiper-pagination-bullet {
      width: 6px;
      height: 3px;
      border-radius: 0;
      background: rgba(255, 255, 255, 0.5);
    }
  }
}

.btn {
  position: relative;
}

.btn-blue-green {
  background: linear-gradient(130deg, #5494d5 16%, #35d562 40%);
  color: #fff;
  position: relative;
  box-shadow: 6px 6px 10px #b5fdbe;
  // font-size: 20px;
  font-weight: 600;
  font-family: Tahoma, Arial, Helvetica, sans-serif;
  min-width: 150px;
  &.no-shadow {
    box-shadow: none;
  }
  &.with-crystal::after {
    content: "";
    background: url(/assets/img/gem.png) no-repeat;
    position: absolute;
    width: 30px;
    height: 30px;
    background-size: contain;
    left: 16px;
    top: 16px;
  }
}

.img-inline-button {
  width: 1.5625rem;
  vertical-align: middle;
  margin: 0 0.1875rem;
}

.toast-container {
  pointer-events: all;
  cursor: pointer;
}

ion-toast {
  --border-radius: 0.5rem;
  --box-shadow: var(--main-card-effects);
  --min-height: 3.375rem;
  --width: 80%;
  --min-width: 18.75rem;
  transform: translateY(-4.375rem);

  &.info {
    --background: var(--card-color-1);
    --color: var(--text-color);
    --button-color: #adadaf;
    // --box-shadow: 0 0 0 0.125rem black;
  }
  &.error {
    --background: #ee7b88;
    --color: white;
    --button-color: white;
  }
  &::part(message) {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 0.875rem;
  }
  &::part(icon) {
    font-size: 1.375rem;
  }

  &::part(button) {
    height: auto;
    width: auto;
    font-size: 1.125rem;
  }
}

.plt-desktop {
  ion-content.custom-scrollbar {
    --offset-bottom: auto !important;
    --overflow: auto;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0.313rem;
      height: 0.313rem;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #33334c;
      border-radius: 999px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--main-color-active);
    }

    .inner-scroll {
      scrollbar-width: thin;
    }
  }
}
