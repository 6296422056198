.btn {
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 1rem;
  text-transform: initial;
  height: auto;
  text-decoration: none;
  border: 0.125rem solid var(--gradient-main);
  background: var(--button-color);

  &:disabled {
  }
}

.btn-gradient-green {
  min-height: 2rem;
  padding: 0.3125rem 0.9375rem;
  background: linear-gradient(180deg, #17fdba 0%, #0be8e8 100%);
  color: #084445;
  box-shadow:
    0px 2px 12px rgba(20, 247, 201, 0.25),
    inset 0px -1px 4px 0.4px rgba(0, 111, 78, 0.4);

  &:disabled {
    color: #a8a8aa;
    background: linear-gradient(180deg, #e9e9e9 0%, #d8d8d8 100%);
    box-shadow:
      0px 2px 12px #eeeeee,
      inset 0px -1px 4px 0.4px rgba(132, 132, 132, 0.4);

    img {
      filter: grayscale(1);
    }
  }
}
.btn-gradient-blue {
  min-height: 2rem;
  padding: 0.3125rem 0.9375rem;
  color: #0a5879;
  background: linear-gradient(180deg, #17fdfd 0%, #01c2ff 100%);
  box-shadow:
    0px 2px 12px rgba(20, 206, 247, 0.25),
    inset 0px -1px 3px 0.4px #108ed5;

  &:disabled {
    color: #a8a8aa;
    background: linear-gradient(180deg, #e9e9e9 0%, #d8d8d8 100%);
    box-shadow:
      0px 2px 12px #eeeeee,
      inset 0px -1px 4px 0.4px rgba(132, 132, 132, 0.4);

    img {
      filter: grayscale(1);
    }
  }
}

.btn-flat-green {
  color: white;
  min-height: 1.75rem;
  padding: 0.3125rem;
  background: #04e9a6;
}
