.modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.modal-content {
  background-color: var(--card-color-1);
  width: 19.8rem;
  border-radius: 1.5rem;
  overflow: hidden;
  box-shadow: var(--card-shadow-small);
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 1rem;
  padding: 0.625rem;
  box-shadow: var(--card-shadow-small);
  position: relative;

  .close-btn {
    position: absolute;
    background: transparent;
    right: 1.125rem;
    top: 45%;
    transform: translateY(-50%);
    width: 0.75rem;
    height: 0.75rem;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }

    // &::before {
    //   content: "\2A09";
    //   position: absolute;
    //   right: 1.125rem;
    //   top: 45%;
    //   transform: translateY(-50%);
    //   font-size: 1rem;
    //   color: #e3e3e3;
    //   margin-top: 3px;
    // }
  }
}

.modal-body {
  padding: 1rem;
}
