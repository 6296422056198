html {
  font-size: 4.44vw;

  @media (min-width: 500px) {
    font-size: calc(22px * var(--app-scale));
  }
  &.bg-visible {
    font-size: calc(22px * var(--app-scale));
  }

  font-size: min(4.44vw, calc(44px * var(--app-scale))) !important;
}
