.default-theme {
  // .btn-primary,
  // .btn-price,
  .progress-bar {
    box-shadow: 0px 4px 10px rgba(255, 65, 117, 0.5);
  }
  .form-tab .tab.active {
    opacity: 1;
    color: #ff826a;
  }
  .top-bar .coins-box .add-coin {
    // box-shadow: 0px 4px 4px rgba(218, 80, 71, .35);
  }
  .bonus-item.active .bonus-coin {
    background: url("/assets/img/bonus-mask.png"),
      linear-gradient(155.33deg, #ffc632 8.77%, #e54952 88.79%);
    box-shadow: 0px 4px 4px rgba(249, 135, 172, 0.6);
  }
  .btn-logout {
    color: #ff826a;
    background: rgba(255, 130, 106, 0.2);
  }
  .loading-screen .bg-gradient {
    background: linear-gradient(
      310deg,
      rgba(239, 108, 112, 0.6) 8.77%,
      rgba(233, 52, 96, 0.6) 88.79%
    );
  }
  .tutorial-block {
    background-image: url("/assets/img/pattern-bg.png");
    background-image: url("/assets/img/pattern-bg.png"),
      linear-gradient(155.33deg, #ffc632 8.77%, #e54952 88.79%);
    box-shadow: 0px -5px 15px rgba(252, 196, 91, 0.3);
  }
  .bonus-item.active .bonus-item_day,
  .prev,
  .close,
  .svg-icon,
  .bonus-coin .coin-current,
  .shop-coin .coin-current,
  .banner .timer,
  .tabs .tab.active,
  .bonut-timer,
  .tab-nav__icon + span.active,
  .tab-nav li.active .tab-nav__icon {
    color: #ff826a !important;
  }
  .btn-primary,
  .btn-price,
  .progress-bar,
  .top-bar .coins-box,
  .switch-wrap input.mobileToggle:checked + label:after {
    background: #79da18;
    // background: linear-gradient(155.33deg, #ffa183 8.78%, #ff3975 88.79%) !important;
  }
  .badge-circle {
    background: #ff2e2e;
  }
  .banner .btn-price:disabled,
  .banner .btn-price[disabled] {
    background: grey !important;
    user-select: none;
  }
  .tab-nav li .button-chat {
    background: linear-gradient(
      155.33deg,
      #ffd25e 8.77%,
      #e73c45 88.79%
    ) !important;
    box-shadow: 0px 4px 6px rgba(216, 66, 70, 0.35) !important;
  }
  .tab-bar .primary-btn {
    color: #e76a53;
    background-color: #ff826a;
  }
  .bonus-item.active .bonus-coin .coin-current {
    color: #fff !important;
  }
  .switch-wrap input.mobileToggle:checked + label:after {
    box-shadow: 0px 3px 6px rgba(255, 65, 117, 0.5) !important;
  }
  .badge-circle {
    // box-shadow: 0px 3px 6px rgba(253, 105, 57, .35);
  }
  .modal-content {
    &::-webkit-scrollbar {
      background-color: var(--main-color-1);
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--main-color-active);
    }
  }
}

.btn-google {
  background-color: #4585f1;
  color: #fff;
  position: relative;
  padding-left: 20px;
  &:before {
    content: "";
    width: 44px;
    height: 44px;
    background-image: url("/assets/img/googleIcon.png");
    background-repeat: no-repeat;
    position: absolute;
    left: 15px;
    top: 5px;
  }
}
.btn-apple {
  background-color: #4585f1;
  color: #fff;
  position: relative;
  padding-left: 20px;
  &:before {
    content: "";
    width: 44px;
    height: 44px;
    background-image: url("/assets/img/apple_logo.png");
    background-repeat: no-repeat;
    background-size: 25px;
    background-position: center;
    position: absolute;
    left: 15px;
    top: 5px;
  }
  &.spinner:before {
    background: none;
  }
}
.btn-twitter {
  background-color: #1da1f2;
  color: #fff;
  position: relative;
  padding-left: 20px;
  &:before {
    content: "";
    width: 44px;
    height: 44px;
    background-image: url("/assets/img/twitter_white.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left: 15px;
    top: 5px;
  }
}
.btn-discord {
  background-color: #6c82cb;
  color: #fff;
  position: relative;
  padding-left: 20px;
  &:before {
    content: "";
    width: 44px;
    height: 44px;
    background-image: url("/assets/img/discordIcon.png");
    background-repeat: no-repeat;
    position: absolute;
    left: 15px;
    top: 3px;
  }
}

.validation-error-block {
  margin-left: 20px;
  color: red;
}

.color-primary {
  color: #ff826a;
}
.text-center {
  text-align: center;
}

.text-link {
  cursor: pointer;
  text-decoration: underline;
}

.display-flex-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button {
    width: 45%;
  }
}

.modal-back-btn {
  position: absolute;
  left: 10px;
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 10px;
  color: #ff826a;
}

.host-scroll-adjust {
  flex: 1 1 auto;
  // height: calc(100% - 147px);
  padding: 20px 20px 5px 20px;
  overflow: auto;

  @media (max-width: 500px) {
    padding: 20px 10px 5px;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 48px;
    border-radius: 15px;
    background-color: #f5f3ff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff826a;
    border-radius: 15px;
  }
}

.btn-price {
  font-size: 17px;
  line-height: 21px;
  text-align: center;
  color: #fff;
  border-radius: 500px;
  font-weight: 600;
  padding: 5px 9px;
  width: 100%;
  max-width: 104px;
  border: none;
}

.btn-buy {
  font-size: 17px;
  line-height: 21px;
  text-align: center;
  color: #fff;
  text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  border-radius: 500px;
  font-weight: 600;
  padding: 9px 9px;
  width: 100%;
  max-width: 104px;
  border: none;
  background: rgb(97, 172, 53);
}

.swiper-pagination-bullet-active {
  background-color: red !important;
}

.transparent-modal {
  --background: transparent;
  --ion-backdrop-opacity: 0.5;
  --ion-backdrop-color: #000000;
  --border-radius: 0;
}
.modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
}
ion-modal {
  --width: 100%;
  --height: 100%;
}
